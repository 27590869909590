import React, { useEffect, useState } from "react";
import { Fade } from "reactstrap";

const CustomFade = ({open, children}) => {

  const [visible, setVisible] = useState(open);
  const [mounted, setMounted] = useState(open);

  useEffect(() => {
    if (open) {
      setMounted(true);
      setTimeout(() => setVisible(true), 10);
    } else {
      setVisible(false);
    }
  }, [open]);

  const handleOnExited = () => {
    setTimeout(() => setMounted(false), 50);
  };

  if (!mounted) {
    return null;
  }

  return (
    <Fade in={visible} timeout={100} onExited={handleOnExited}>
      {children}
    </Fade>
  );

}

export default CustomFade;