import React from "react"
import { Page } from "../../commons/components/Page";
import { Col, Container, Row } from "reactstrap";
import PrintOrderList from "../components/PrintOrderList";

const PrintOrderPage = () => {

  return (
    <Page>
      <Container fluid>
        <Row>
          <Col>
            <PrintOrderList />
          </Col>
        </Row>
      </Container>
    </Page>
  );
}

export default PrintOrderPage;