import { getCssVariable } from "../../commons/functions";

export const chart_colors = [
      getCssVariable('--zc-secondary-color'),
      getCssVariable("--zc-secondary-color-light"),
      getCssVariable("--zc-primary-color-dark"),
      getCssVariable("--zc-accent-color-dark")
];

export const calculateNps = (data) => {

      // Calculate NPS inside useEffect
      let promoters = 0;
      let detractors = 0;
      let totalResponses = 0;

      // Process each item in the data array
      data.forEach(item => {
      const { npsLevel, counter } = item;
      totalResponses += counter;

      if (npsLevel >= 9 && npsLevel <= 10) {
      promoters += counter;
      } else if (npsLevel >= 0 && npsLevel <= 6) {
      detractors += counter;
      }
      // Passives (7-8) are not counted in the calculation
      });

      // Apply the NPS formula
      const calculatedNPS = totalResponses > 0 
      ? Math.round(((promoters - detractors) / totalResponses) * 100)
      : 0;

      return calculatedNPS;

}