import moment from "moment";
import React, { useEffect, useState } from "react";
import Chart from "react-google-charts";
import { Button, ButtonGroup, Card, CardBody, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Form, Input, Label, Table } from "reactstrap";
import { getCssVariable } from "../../commons/functions";
import { FormattedMessage, useIntl } from "react-intl";
import Select from "react-select";
import CustomSpinner from "../../commons/components/Spinner";
import MetricsService from "../services/MetricsService";
import { useQuery } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import InputErrors from "../../commons/components/InputErrors";
import Moment from "react-moment";
import { calculateNps } from "./utils";

const GROUP_TYPE_DAY = "day";
const GROUP_TYPE_WEEK = "week";
const GROUP_TYPE_MONTH = "month";

const GROUP_TYPES = [
  GROUP_TYPE_DAY,
  GROUP_TYPE_WEEK,
  GROUP_TYPE_MONTH
]

const CHART_COLUMN = "ColumnChart";
const CHART_LINE = "LineChart";
const CHART_TABLE = "Table";
const CHART_METRIC = "Metric";

const CHART_TYPES = [
  CHART_COLUMN,
  CHART_LINE,
  CHART_TABLE,
  CHART_METRIC
]

const DATE_TYPE_CUSTOM = "CUSTOM";
const DATE_TYPE_TODAY = "TODAY";
const DATE_TYPE_YESTERDAY = "YESTERDAY";
const DATE_TYPE_SEVEN_DAYS = "7D";
const DATE_TYPE_ONE_MONTH = "30D";
const DATE_TYPE_THREE_MONTHS = "3M";
const DATE_TYPE_SIX_MONTHS = "6M";
const DATE_TYPE_TWELVE_MONTHS = "12M";

const DATE_TYPES = [
  DATE_TYPE_TODAY,
  DATE_TYPE_YESTERDAY,
  DATE_TYPE_SEVEN_DAYS,
  DATE_TYPE_ONE_MONTH,
  DATE_TYPE_THREE_MONTHS,
  DATE_TYPE_SIX_MONTHS,
  DATE_TYPE_TWELVE_MONTHS
];

const METRIC_REGISTRATIONS = "REGISTRATIONS";
const METRIC_PRINT_ORDERS = "PRINT_ORDERS";
const METRIC_PAGES_PRINTED = "PAGES_PRINTED";
const METRIC_WEB_AD_CLICKS = "WEB_AD_CLICKS"
const METRIC_WEB_AD_VIEWS = "WEB_AD_VIEWS";
const METRIC_DISCOUNT_USAGE = "DISCOUNT_USAGE";
const METRIC_DISCOUNT_USAGE_MONEY = "DISCOUNT_USAGE_MONEY";
const METRIC_NPS = "NPS";
const METRIC_UPLOADS = "UPLOADS";
const METRIC_UNIQUE_PRINTING_USERS = "UNIQUE_PRINTING_USERS";
const METRIC_ACTIVE_USERS = "ACTIVE_USERS";

const METRICS = [
  METRIC_REGISTRATIONS,
  METRIC_PRINT_ORDERS,
  METRIC_PAGES_PRINTED,
  METRIC_WEB_AD_CLICKS,
  METRIC_WEB_AD_VIEWS,
  METRIC_DISCOUNT_USAGE,
  METRIC_DISCOUNT_USAGE_MONEY,
  METRIC_NPS,
  METRIC_UPLOADS,
  METRIC_UNIQUE_PRINTING_USERS,
  METRIC_ACTIVE_USERS
];

const MetricChart = ({type = "numeric", onMetricTypeChange}) => {

  const intl = useIntl();

  const [data, setData] = useState();
  const [dateType, setDateType] = useState(DATE_TYPE_ONE_MONTH);
  const [filter, setFilter] = useState({});
  const [chartType, setChartType] = useState(CHART_COLUMN);
  const [groupType, setGroupType] = useState(GROUP_TYPE_MONTH);
  const [metricType, setMetricType] = useState(METRIC_PAGES_PRINTED);
  const [field, setField] = useState('value');
  const [loading, setLoading] = useState(false);
  const [customDate, setCustomDate] = useState(false);

  const result = useQuery({
    queryKey: ["metrics-dashboard", metricType, filter], 
    queryFn: () => {
      let localMetricType = metricType;
      if (metricType === METRIC_DISCOUNT_USAGE_MONEY) {
        localMetricType = METRIC_DISCOUNT_USAGE;
      }

      return MetricsService.obtainMetric(localMetricType, filter.startDate, filter.endDate)
    },
    onSuccess: (response) => setData(response.data)
  });

  useEffect(() => {

    setLoading(true);

    const timerId = setTimeout(() => setLoading(false), 300);

    return () => clearTimeout(timerId);

  }, [chartType, groupType]);

  useEffect(() => {

    const dateFormat = "YYYY-MM-DD";
    let startDate = null;
    let endDate = null;

    switch(dateType) {
      case DATE_TYPE_TODAY:
        const todayStr = moment().format(dateFormat);
        startDate = todayStr;
        endDate = todayStr;
        break;
      case DATE_TYPE_YESTERDAY:
        const yesterdayStr = moment().subtract(1, "day").format(dateFormat);
        startDate = yesterdayStr;
        endDate = yesterdayStr;
        break;
      case DATE_TYPE_SEVEN_DAYS:
        startDate = moment().subtract(7, "day").format(dateFormat);
        endDate = moment().format(dateFormat);
        break;
      case DATE_TYPE_ONE_MONTH:
        startDate = moment().subtract(1, "month").format(dateFormat);
        endDate = moment().format(dateFormat);
        break;
      case DATE_TYPE_THREE_MONTHS:
        startDate = moment().subtract(3, "month").format(dateFormat);
        endDate = moment().format(dateFormat);
        break;
      case DATE_TYPE_SIX_MONTHS:
        startDate = moment().subtract(6, "month").format(dateFormat);
        endDate = moment().format(dateFormat);
        break;
      case DATE_TYPE_TWELVE_MONTHS:
        startDate = moment().subtract(12, "month").format(dateFormat);
        endDate = moment().format(dateFormat);
        break;
    }

    if (startDate !== null) {
      setFilter({
        "startDate": startDate,
        "endDate": endDate
      });
    }

  }, [dateType]);

  /*const xAxisLength = dataForChart ? Object.keys(dataForChart).length : 0;
  let ticksSize = 1;
  if (xAxisLength > 20 && xAxisLength < 60) {
    ticksSize = 4;
  } else if (xAxisLength < 90) {
    ticksSize = 10;
  }*/

  let chartOptions = {
    width: "100%",
    height: 300,
    legend: { position: 'none' },
    interpolateNulls: true,
    hAxis: {
      title: "",
      textStyle:{color: getCssVariable('--zc-secondary-color')},
      slantedText: false,
      slantedTextAngle: 45, // Angle for better readability
      //ticks: dataForChart ? dataForChart.filter((_, index) => index % ticksSize === 0).map(row => row[0]) : 1
    },
    vAxis: {
      title: "",
      textStyle:{color: '#516b85'},
      viewWindow: {
        min: 0  // Ensures the y-axis starts from 0
      }
    },
    bar: {
      groupWidth: "95%"
    },
    annotations: {
      alwaysOutside: false,
      textStyle: {
        fontSize: 12,
      }
    },
    dataLabels: {
      enabled: true
    }
  };

  let viewsParam = {};

  if (chartType === CHART_TABLE) {
    chartOptions = {
      width: "95%",
      height: 220,
      allowHtml: true,
      sort: "disable"
    };

    viewsParam = { 
      columns: [0, 1]
    };
  }

  const generateOptions = (items) => {
    return items.map(item => {return {value: item};})
  }

  const handleOnChangeType = (selection) => {
    setGroupType(selection.value);
  }

  const handleOnChangeChartType = (selection) => {
    setChartType(selection.value);
  }

  const handleOnChangeDateType = (e) => {
    setDateType(e?.currentTarget?.dataset.type);
  }

  const handleOnChangeMetricType = (selection) => {
    setMetricType(selection.value);

    if (selection.value === METRIC_DISCOUNT_USAGE_MONEY) {
      setField("value2");
    } else {
      setField("value");
    }

    if (typeof onMetricTypeChange === "function") {
      onMetricTypeChange(selection.value);
    }
  }

  const handleApplyCustomDate = (data) => {
    
    setFilter({
      "startDate": data.startDate,
      "endDate": data.endDate
    });
    setDateType(DATE_TYPE_CUSTOM);
    toggleCustomDate();
  }

  const toggleCustomDate = () => setCustomDate(prev => !prev)

  return (
    <Card className="h-100">
      <CardBody>
        <div className="d-flex align-items-center flex-wrap">
          <div className="flex-grow-1">
            <ButtonGroup size="sm">
              <Dropdown isOpen={customDate} toggle={toggleCustomDate} group className="custom-datepicker-dropdown">
                <DropdownToggle outline active={DATE_TYPE_CUSTOM === dateType}>
                  <i className="fas fa-calendar-day me-2"></i>
                  { DATE_TYPE_CUSTOM === dateType ? <span><Moment format="MMM DD, YYYY" parse="YYYY-MM-DD">{filter.startDate}</Moment> - <Moment format="MMM DD, YYYY" parse="YYYY-MM-DD">{filter.endDate}</Moment></span> : <FormattedMessage id="xxx" defaultMessage="Custom" /> }
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem tag={Wrapper}>
                    <CustomDate toggle={toggleCustomDate} apply={handleApplyCustomDate} defaultValues={DATE_TYPE_CUSTOM === dateType ? filter : {}} />
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
              {DATE_TYPES.map((dt, index) => <Button key={index} data-type={dt} outline active={dt === dateType} onClick={handleOnChangeDateType}>{dt}</Button>)}
            </ButtonGroup>
          </div>
          <div className="d-flex align-items-center gap-2">
            <Select onChange={handleOnChangeMetricType} options={generateOptions(METRICS)} value={{value: metricType}} isSearchable={false}
              getOptionLabel={opt => intl.formatMessage({id: `metrics.metricType.${opt.value}`, defaultMessage: opt.value})}
              classNamePrefix="zc-select" />
            <Select onChange={handleOnChangeType} options={generateOptions(GROUP_TYPES)} value={{value: groupType}} isSearchable={false}
              getOptionLabel={opt => intl.formatMessage({id: `metrics.groupType.${opt.value}`, defaultMessage: opt.value})}
              classNamePrefix="zc-select" />
            <Select onChange={handleOnChangeChartType} options={generateOptions(CHART_TYPES)} value={{value: chartType}} isSearchable={false}
              getOptionLabel={opt => intl.formatMessage({id: `metrics.chartType.${opt.value}`, defaultMessage: opt.value})}
              classNamePrefix="zc-select" />
          </div>
        </div>
        { loading || result.isLoading && <div className="h-100 d-flex align-items-center justify-content-center"><CustomSpinner /></div>}
        { (!result.isLoading && !loading) || !Array.isArray(data) && <div className="font-weight-bold fs-3 h-100 d-flex align-items-center justify-content-center"><FormattedMessage id="metrics.noData" /></div>}
        { !loading && !result.isLoading && Array.isArray(data) &&
          <MetricChartTypeWrapper groupType={groupType} metricType={metricType} dataType={type} field={field} data={data} filter={filter}
            chartType={chartType} chartOptions={chartOptions} viewsParam={viewsParam} /> }
      </CardBody>
    </Card>
  );
}

const Wrapper = ({children, ...props}) => <div {...props}>{children}</div>

export default MetricChart;

const MetricChartTypeWrapper = ({groupType, metricType, chartType, ...props}) => {

  let MetricGroupTypeChart = null;

  if (metricType === METRIC_NPS) {
    MetricGroupTypeChart = NpsChart;
  } else if (chartType === CHART_METRIC) {
    MetricGroupTypeChart = MetricDisplay;
  } else {

    switch (groupType) {
      case GROUP_TYPE_DAY:
        MetricGroupTypeChart = DayChart;
        break;
      case GROUP_TYPE_WEEK:
        MetricGroupTypeChart = WeekChart;
        break;
      case GROUP_TYPE_MONTH:
        MetricGroupTypeChart = MonthChart;
        break;
    }

  }

  return <MetricGroupTypeChart metricType={metricType} chartType={chartType} {...props} />;
}

const MetricDisplay = ({data, metricType, field}) => {

  const [value, setValue] = useState(0);

  useEffect(() => {

    if (Array.isArray(data)) {

      const newValue = data.reduce((acc, item) => {
        return acc + item[field];
      }, 0);

      setValue(newValue);
    }

  }, [data]);

  return (
    <div className="d-flex align-items-center justify-content-center" style={{minHeight: "100px"}}>
        <div className="fw-bold border rounded p-3 text-center">
          <div className="fs-4">{value}</div>
          <div className="mt-2 small">
            <FormattedMessage id={`metrics.metricType.${metricType}`} />
          </div>
        </div>
    </div>
  );
}

const MonthChart = ({dataType, data, field, chartType, chartOptions, viewsParam, filter}) => {

  const [dataForChart, setDataForChart] = useState();

  useEffect(() => {

    const postProcessGroupedData = (groupedData) => {

      const minDate = moment(filter.startDate, "YYYY-MM-DD");
      const maxDate = moment(filter.endDate, "YYYY-MM-DD");

      let tempDate = minDate.clone();

      while (tempDate.isSameOrBefore(maxDate, 'month')) {
        const key = tempDate.format("MM/YYYY");

        if (!(key in groupedData)) {
          groupedData[key] = 0;
        }

        tempDate.add(1, "month");
      }

    }

    const sortKeys = (a, b) => moment(a, "M/YYYY").diff(moment(b, "M/YYYY"));

    const groupedData = data.reduce((acc, item) => {

      const mDate = moment(item.date, "DD/MM/YYYY");
      const key = mDate.format("MM/YYYY");

      if (!acc.hasOwnProperty(key)) {
        acc[key] = 0;
      }
    
      acc[key] += item[field];

      return acc;

    }, {});

    postProcessGroupedData(groupedData);

    const sortedKeys = Object.keys(groupedData).sort(sortKeys);

    setDataForChart([
      ["Date", "Value", { role: 'annotation' }, {role: "style"}],
      ...sortedKeys.map(itemKey => {

        let value = groupedData[itemKey];
        if (dataType === "currency") {
          value = (value / 100);
        }

        return [itemKey, value, dataType === "currency" ? value + " €" : value, `color: ${getCssVariable('--zc-secondary-color')}`]
      })
    ]);

  }, [data]);

  return (
    <Chart className={chartType} chartType={chartType} data={dataForChart} options={chartOptions}
            chartWrapperParams={{view: viewsParam}} />
  );
}

const WeekChart = ({dataType, data, field, chartType, chartOptions, viewsParam, filter}) => {

  const [dataForChart, setDataForChart] = useState();

  useEffect(() => {

    const postProcessGroupedData = (groupedData) => {
      
      const minDate = moment(filter.startDate, "YYYY-MM-DD");
      const maxDate = moment(filter.endDate, "YYYY-MM-DD");

      let tempDate = minDate.clone();

      // Fill in missing weeks
      while (tempDate.isSameOrBefore(maxDate, 'isoWeek')) {
        const key = tempDate.format("DD/MM/YYYY");
        
        if (!(key in groupedData)) {
          groupedData[key] = 0;
        }
        
        tempDate.add(1, "week");
      }
    }

    const sortKeys = (a, b) => moment(a, "WW/YYYY", true).diff(moment(b, "WW/YYYY", true));

    const groupedData = data.reduce((acc, item) => {
      const mDate = moment(item.date, "DD/MM/YYYY");
      
      const firstDayOfWeek = mDate.clone().startOf('isoWeek');
      const key = firstDayOfWeek.format("DD/MM/YYYY");
      
      if (!acc.hasOwnProperty(key)) {
        acc[key] = 0;
      }
      
      acc[key] += item[field];
      
      return acc;
    }, {});

    postProcessGroupedData(groupedData);

    const sortedKeys = Object.keys(groupedData).sort(sortKeys);

    setDataForChart([
      ["Date", "Value", { role: 'annotation' }, {role: "style"}],
      ...sortedKeys.map(itemKey => {

        let value = groupedData[itemKey];
        if (dataType === "currency") {
          value = (value / 100);
        }

        return [itemKey, value, dataType === "currency" ? value + " €" : value, `color: ${getCssVariable('--zc-secondary-color')}`]
      })
    ]);

  }, [data]);

  return (
    <Chart className={chartType} chartType={chartType} data={dataForChart} options={chartOptions}
            chartWrapperParams={{view: viewsParam}} />
  );
}

const DayChart = ({dataType, data, field, chartType, chartOptions, viewsParam, filter}) => {

  const [dataForChart, setDataForChart] = useState();

  useEffect(() => {
  
    const postProcessGroupedData = (groupedData) => {
      const minDate = moment(filter.startDate, "YYYY-MM-DD");
      const maxDate = moment(filter.endDate, "YYYY-MM-DD");

      let tempDate = minDate.clone();
      
      while (tempDate.isSameOrBefore(maxDate, 'day')) {
        const key = tempDate.format("DD/MM/YYYY");
        
        if (!(key in groupedData)) {
          groupedData[key] = 0;
        }
        
        tempDate.add(1, "day");
      }
    }

    const sortKeys = (a, b) => moment(a, "DD/MM/YYYY").diff(moment(b, "DD/MM/YYYY"));

    const groupedData = data.reduce((acc, item) => {
      const key = item.date;
  
      if (!acc.hasOwnProperty(key)) {
        acc[key] = 0;
      }
    
      acc[key] += item[field];
  
      return acc;
    }, {});

    postProcessGroupedData(groupedData);

    const sortedKeys = Object.keys(groupedData).sort(sortKeys);

    setDataForChart([
      ["Date", "Value", { role: 'annotation' }, {role: "style"}],
      ...sortedKeys.map(itemKey => {

        let value = groupedData[itemKey];
        if (dataType === "currency") {
          value = (value / 100);
        }

        return [itemKey, value, dataType === "currency" ? value + " €" : value, `color: ${getCssVariable('--zc-secondary-color')}`]
      })
    ]);

  }, [data]);

  return (
    <Chart className={chartType} chartType={chartType} data={dataForChart} options={chartOptions}
            chartWrapperParams={{view: viewsParam}} />
  );
}

const NpsChart = ({data}) => {

  const [npsScore, setNpsScore] = useState(0);
  
  useEffect(() => {

    if (!data) {
      return;
    }
    
    setNpsScore(calculateNps(data));
  }, [data]);

  return (
    <div className="metric-nps d-flex align-items-center gap-3 mt-3">
      <Card style={{minHeight: "83px"}}>
        <CardBody>
          <div className="d-flex align-items-center justify-content-center h-100">
            <div className={`font-weight-bold fs-4 ${getNpsScoreGlobalClassName(npsScore)}`}>
              {npsScore}
            </div>
          </div>
        </CardBody>
      </Card>
      <div>
        <Table bordered className="nps-table mb-0">
          <thead>
            <tr>
              <th scope="row">
                <FormattedMessage id="metrics.nps.score" />
              </th>
              {Array.from({ length: 10 }, (_, i) => (
                <th key={i + 1} scope="col" className={`nps-column ${getNpsScoreClassName(i + 1)}`}>
                  {i + 1}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">
                <FormattedMessage id="metrics.nps.counter" />
              </th>
              {Array.from({ length: 10 }, (_, i) => {
                const levelData = data?.find(row => row.npsLevel === i + 1);
                return <td key={i + 1}>{levelData ? levelData.counter : 0}</td>;
              })}
            </tr>
          </tbody>
        </Table>
      </div>
    </div>
  );

}

const getNpsScoreClassName = (score) => {

  let outputClassName = "";

  if (score >= 9 && score <= 10) {
    outputClassName = "promoters";
  } else if (score >= 0 && score <= 6) {
    outputClassName = "detractors";
  }

  return outputClassName;

}

const getNpsScoreGlobalClassName = (score) => {

  let outputClassName = "text-danger";
  if (score >= 40) {
    outputClassName = "text-zc";
  } else if (score >= 25) {
    outputClassName = "text-warning";
  }

  return outputClassName;
}

const CustomDate = ({toggle, apply, defaultValues}) => {

  const { register, handleSubmit, setValue, setError, setFocus, formState: { errors } } = useForm({
    mode: "onSubmit",
    "defaultValues": defaultValues
  });

  const { ref: refStartDate, ...registerStartDate } = register('startDate', { required: true });
  const { ref: refEndDate, ...registerEndDate } = register('endDate', { required: true });

  const maxDate = moment().format("YYYY-MM-DD")
  
  return (
    <Form onSubmit={handleSubmit(apply)} onClick={(e) => e.stopPropagation()}>
      <div className="d-flex flex-column gap-2">
        <div className="d-flex gap-2 align-items-center">
            <Label for="startDate" style={{minWidth: "40px"}}>
              <FormattedMessage id="xxx" defaultMessage="Start" />
            </Label>
            <Input type="date" name="startDate" innerRef={refStartDate} {...registerStartDate} max={maxDate}/>
            <InputErrors fieldName="startDate" errors={errors} />
        </div>
        <div className="d-flex gap-2 align-items-center">
            <Label for="endDate" style={{minWidth: "40px"}}>
              <FormattedMessage id="xxx" defaultMessage="End" />
            </Label>
            <Input type="date" name="endDate" innerRef={refEndDate} {...registerEndDate} max={maxDate} />
            <InputErrors fieldName="endDate" errors={errors} />
        </div>
        <div className="d-flex gap-2 justify-content-end">
          <Button outline size="sm" color="secondary" onClick={toggle}>
            <FormattedMessage id="buttons.cancel" />
          </Button>
          <Button size="sm" color="secondary">
          <FormattedMessage id="buttons.apply" defaultMessage="Apply" />
          </Button>
        </div>
      </div>
    </Form>
  );
}
