import api from "../../Api";
import { buildApiHeaders } from "../../commons/functions";

class PrintOrderService {

  static async list(page, pageSize, filter) {
    const headers = buildApiHeaders();
    return api.get("/v1/admin/printing", {params: {page: page, pageSize: pageSize, ...filter}, headers: headers});
  }

}

export default PrintOrderService;