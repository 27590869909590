import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Label, Row, Table } from "reactstrap";
import MetricsService from "../services/MetricsService";
import CustomSpinner from "../../commons/components/Spinner";
import { FormattedMessage, FormattedNumber, useIntl } from "react-intl";

const DEFAULT_PARAM_PRINT_COST = 0.026;

const OverallDashboard = () => {

  const intl = useIntl();

  const [data, setData] = useState();
  const [printCost, setPrintCost] = useState(DEFAULT_PARAM_PRINT_COST);

  const result = useQuery({
    queryKey: ["overall-dashboard"], 
    queryFn: () => MetricsService.obtainOverallDashboard(),
    onSuccess: (response) => setData(response.data)
  });

  const handlePrintCostChange = (e) => {
    let newValue = e.currentTarget.value;
    if (Number.isNaN(Number.parseFloat(newValue))) {
      newValue = 0;
    }
    setPrintCost(newValue);
  }

  let content = null;

  if (result.isLoading || result.isRefetching) {
    content = (
      <CustomSpinner />
    );
  } else if (!data) {
    content = (
      <div className="text-center">
        <FormattedMessage id="metrics.noData" />
      </div>
    );
  } else {
    content = (
      <>
        <Table bordered responsive>
          <thead>
            <tr>
              <th scope="col"></th>
              {data.months.map(({year, month}, index) => {
                return (
                    <th key={index} scope="col">{year + "/" + month.toString().padStart(2, '0')}</th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            <tr className="table-active">
              <th colSpan={data.months.length + 1}>
                <FormattedMessage id="metrics.overallDashboard.section.revenue" />
              </th>
            </tr>
            <DashboardRow title={intl.formatMessage({id: "metrics.overallDashboard.paidPagesValues"})} months={data.months} data={data.paidPagesValues} type="currency" />
            <DashboardRow title={intl.formatMessage({id: "metrics.overallDashboard.paidPages"})} months={data.months} data={data.paidPages} />
            <DashboardHeaderVariableCost data={data} printCost={printCost} />
            <DashboardRow title={intl.formatMessage({id: "metrics.overallDashboard.printedPages"})} months={data.months} data={data.printedPages} />
            <DashboardRow title={intl.formatMessage({id: "metrics.overallDashboard.discountValues"})} months={data.months} data={data.discountValues} type="currency" negative={true} />
            <tr className="table-active">
              <th colSpan={data.months.length + 1}>
                <FormattedMessage id="metrics.overallDashboard.section.userSide" />
              </th>
            </tr>
            <DashboardRow title={intl.formatMessage({id: "metrics.overallDashboard.activeUsers"})} months={data.months} data={data.activeUsers} />
            <DashboardRow title={intl.formatMessage({id: "metrics.overallDashboard.nps"})} months={data.months} data={data.nps} />
            <DashboardRow title={intl.formatMessage({id: "metrics.overallDashboard.registrations"})} months={data.months} data={data.registrations} />
            <tr className="table-active">
              <th colSpan={data.months.length + 1}>
                <FormattedMessage id="metrics.overallDashboard.section.others" />
              </th>
            </tr>
            <DashboardRow title={intl.formatMessage({id: "metrics.overallDashboard.clicks"})} months={data.months} data={data.clicks} />
            <DashboardRow title={intl.formatMessage({id: "metrics.overallDashboard.uploads"})} months={data.months} data={data.uploads} />
            <DashboardRow title={intl.formatMessage({id: "metrics.overallDashboard.campaignViews"})} months={data.months} data={data.campaignViews} />
            <DashboardRow title={intl.formatMessage({id: "metrics.overallDashboard.uniquePrintingUsers"})} months={data.months} data={data.uniquePrintingUsers} />
          </tbody>
        </Table>
        <div className="mt-2">
          <div className="d-flex align-items-center mb-2 pb-2 border-bottom">
            <h4 className="fs-5 fw-bold mb-0">
              <FormattedMessage id="xxx" defaultMessage="Parameters" />
            </h4>
            <span className="text-muted small ms-3 fst-italic">
              <FormattedMessage id="xxx" defaultMessage="These parameters are not saved, just live data calculations." />
            </span>
          </div>
          <div>
            <Label for="inputPrintingCost" className="fw-bold me-2">
              <FormattedMessage id="xxx" defaultMessage="Average print costs excl VAT:" />
            </Label>
            <input type="text" id="inputPrintingCost" className="text-end" value={printCost} onChange={handlePrintCostChange} />
          </div>
        </div>
      </>
    );
  }

  return (
    <Container fluid className="dashboard-page mb-3">
      <Row>
        <Col>
          <div className="d-flex align-items-center section-title">
            <h2 className="fs-5 flex-grow-1 mb-0">
              <FormattedMessage id="metrics.overallDashboard.title" /> {data?.months ? data.months[0].year + "/" + data.months[data.months.length - 1].year : null}
            </h2>
            <Button onClick={result.refetch} size="sm" color="secondary">
              <i className="fas fa-sync-alt"></i>
            </Button>
          </div>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          {content}
        </Col>
      </Row>
    </Container>
  );
}

export default OverallDashboard;

const DashboardRow = ({months, data, type = "numeric", negative = false, title}) => {

  if (!data) {
    return (
      <tr>
        <th scope="row">{title}</th>
        <td colSpan={months.length}>
          <FormattedMessage id="metrics.noData" />
        </td>
      </tr>
    );
  }

  return (
    <tr>
      <th scope="row">{title}</th>
      {months.map(({year, month}, index) => {

        const filterFunc = (item) => {
          return year === item.year && month === item.month;
        };

        return (
          <td key={index} className="text-end">
            {negative && <>&minus;</>}
            {type === "currency" && <><FormattedNumber value={data.find(filterFunc)?.counter / 100 || 0 } />&nbsp;€</> }
            {type === "numeric" && <FormattedNumber value={data.find(filterFunc)?.counter || 0 } /> }
          </td>
        );
      })}
    </tr>
  );
}

const DashboardHeaderVariableCost = ({data, printCost}) => {

  return (
    <tr className="table-active">
      <th role="rowheader">
        <FormattedMessage id="metrics.overallDashboard.section.variableCost" />
      </th>
      {data.months.map(({year, month}, index) => {

        const filterFunc = (item) => {
          return year === item.year && month === item.month;
        };

        let printedPagesCost = 0;
        if (data?.printedPages) {
          printedPagesCost = data.printedPages.find(filterFunc)?.counter / 100 || 0;
        }

        printedPagesCost = printedPagesCost * (printCost * 100);

        let discountCost = 0;
        if (data.discountValues) {
          discountCost = data.discountValues.find(filterFunc)?.counter / 100 || 0;
        }

        console.log(printedPagesCost, discountCost);

        return (
          <th key={index} className="text-end">
            <><FormattedNumber value={printedPagesCost + discountCost} maximumFractionDigits={2}/>&nbsp;€</>
          </th>
        );
        })}
    </tr>
  );
}