import React, { useEffect, useState } from "react";
import { Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap";
import { Page } from "../../commons/components/Page";
import CustomSpinner from "../../commons/components/Spinner";
import MetricsService from "../services/MetricsService";
import MetricsForRegistrations from "../components/MetricForRegistrations";
import MetricsForPrintings from "../components/MetricsForPrintings";
import MetricsForPages from "../components/MetricsForPages";
import MetricForPromoCodes from "../components/MetricForPromoCodes";
import MetricForWebAdClicks from "../components/MetricForWebAdClicks";

import './MetricsPage.css';
import MetricNps from "../components/MetricNps";
import { FormattedMessage } from "react-intl";
import OverallDashboard from "../components/OverallDashboard";
import MetricChart from "../components/MetricChart";

const MetricsPage = () => {

  const [activeTab, setActiveTab] = useState("dashboard");

  const changeTab = (e) => {
    setActiveTab(e.currentTarget.dataset.tabId);
  }

  return (
    <Page>
      <div className="p-2">
        <Nav tabs>
          <NavItem>
            <NavLink data-tab-id="dashboard" onClick={changeTab} active={activeTab === "dashboard"}>
              <FormattedMessage id="metrics.tabs.dashboard" />
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink data-tab-id="metrics" onClick={changeTab} active={activeTab === "metrics"}>
              <FormattedMessage id="metrics.tabs.metrics" />
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="dashboard" className="border border-top-0 py-3">
            <OverallDashboard />
          </TabPane>
          <TabPane tabId="metrics" className="border border-top-0 py-3">
            <Container fluid className="metrics-page mb-3">
              <Row className="mb-3">
                <Col>
                  <MetricChart />
                </Col>
              </Row>
            </Container>
          </TabPane>
        </TabContent>
      </div>
    </Page>
  );
}

export default MetricsPage;