import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { FormattedMessage, FormattedNumber, useIntl } from "react-intl";
import { Form, FormGroup, Input, InputGroup, InputGroupText, Label } from "reactstrap";
import InputErrors from "../../commons/components/InputErrors";
import { randomCodeBetweenFiveAndTenCharacters } from "../../commons/functions";
import moment from "moment";

const PromoCodeForm = ({id, onSave, defaultValues, disabled = false, values = [], categories = []}) => {

  const intl = useIntl();

  const { register, handleSubmit, setValue, watch, setError, setFocus, formState: { errors } } = useForm({
    mode: "onSubmit",
    defaultValues: defaultValues
  });

  useEffect(() => {
    setFocus("name");
  }, [setFocus]);

  const { ref: refName, ...registerName } = register('name', { required: true });
  const { ref: refCode, ...registerCode } = register('code', { required: true });
  const { ref: refStartDate, ...registerStartDate } = register('startDate', { required: true });
  const { ref: refEndDate, ...registerEndDate } = register('endDate', { required: true });
  const { ref: refValue, ...registerValue } = register('valueName', { });
  const { ref: refCustomValue, ...registerCustomValue } = register('value', { max: { value: 99.99, message: intl.formatMessage({id: "validations.field.max"}, {"max": "99.99"})}});
  const { ref: refCategory, ...registerCategory } = register('category');
  const { ref: refUserEmail, ...registerUserEmail } = register('userEmail', { required: false });

  const valueRef = watch("valueName");

  const onSubmit = (data) => {

    if (data.valueName === "" && data.value === "") {
      setError(
        "value",
        {
            "type": "manual",
            "message": intl.formatMessage({id: "printing.promoCodes.msg.error.valueRequired"})
        }
      );
      return;
    }

    if (data.value !== "") {
      data.value = data.value * 100;
    }
    data.valueRef = data.valueName;
    onSave(data, setError);
  };

  const handleRandomCode = () => {
    setValue("code", randomCodeBetweenFiveAndTenCharacters());
  }


  const minDate = moment().format("YYYY-MM-DD");

  return (
    <Form id={id} onSubmit={handleSubmit(onSubmit)}>
      <FormGroup>
        <Label for='name'>
          <FormattedMessage id="printing.promoCodes.fields.name" />
        </Label>
        <Input id="name" name="name" innerRef={refName} {...registerName} disabled={disabled} />
        <InputErrors fieldName="name" errors={errors} />
      </FormGroup>
      <FormGroup>
        <Label for='code'>
          <FormattedMessage id="printing.promoCodes.fields.code" />
        </Label>
        <InputGroup>
          <Input id="code" name="code" innerRef={refCode} {...registerCode} disabled={disabled} />
          <InputGroupText onClick={handleRandomCode} role="button">
            <i className="fas fa-random"></i>
          </InputGroupText>
        </InputGroup>
        <InputErrors fieldName="code" errors={errors} />
      </FormGroup>
      <FormGroup>
        <Label for='startDate'>
          <FormattedMessage id="printing.promoCodes.fields.startDate" />
        </Label>
        <Input id="startDate" name="startDate" type="date" min={minDate} innerRef={refStartDate} {...registerStartDate} disabled={disabled} />
        <InputErrors fieldName="startDate" errors={errors} />
      </FormGroup>
      <FormGroup>
        <Label for='endDate'>
          <FormattedMessage id="printing.promoCodes.fields.endDate" />
        </Label>
        <Input id="endDate" name="endDate" type="date" min={minDate} innerRef={refEndDate} {...registerEndDate} disabled={disabled} />
        <InputErrors fieldName="endDate" errors={errors} />
      </FormGroup>
      <FormGroup>
        <Label for='valueName'>
          <FormattedMessage id="printing.promoCodes.fields.value" />
        </Label>
        <Input id="valueName" name="valueName" innerRef={refValue} {...registerValue} disabled={disabled} type="select">
          {values.map(i => <option key={i.key} value={i.key}><FormattedNumber value={i.value / 100} maximumFractionDigits={2} />&nbsp;€</option>)}
          <option value=""><FormattedMessage id="printing.promoCodes.fields.value.other" /></option>
        </Input>
        <InputErrors fieldName="valueName" errors={errors} />
      </FormGroup>
      {valueRef === "" && <FormGroup>
        <Label for='value'>
          <FormattedMessage id="printing.promoCodes.fields.customValue" />
        </Label>
        <Input id="value" name="value" type="number" step="0.01" innerRef={refCustomValue} {...registerCustomValue} disabled={disabled} />
        <InputErrors fieldName="value" errors={errors} />
      </FormGroup> }
      <FormGroup>
        <Label for='category'>
          <FormattedMessage id="printing.promoCodes.fields.category" />
        </Label>
        <Input id="category" name="category" innerRef={refCategory} {...registerCategory} disabled={disabled} type="select">
          <option value=""></option>
          {categories.map(i => <option key={i.key} value={i.key}>{i.value}</option>)}
        </Input>
        <InputErrors fieldName="category" errors={errors} />
      </FormGroup>
      { !defaultValues?.id && 
      <FormGroup>
        <Label for='userEmail'>
          <FormattedMessage id="printing.promoCodes.fields.userEmail" />
        </Label>
        <Input id="userEmail" name="userEmail" innerRef={refUserEmail} {...registerUserEmail} disabled={disabled} />
        <InputErrors fieldName="userEmail" errors={errors} />
      </FormGroup> }
    </Form>
  );
};

export default PromoCodeForm;