import api from "../../Api";
import { buildApiHeaders } from "../../commons/functions";

class MetricsService {

  static async obtainMetric(metricType, startDate, endDate) {
    const headers = buildApiHeaders();
    return api.get("/v1/admin/metrics", {headers: headers, params: {"type": metricType, "startDate": startDate, "endDate": endDate}});
  }

  static async obtainOverallDashboard() {
    const headers = buildApiHeaders();
    return api.get("/v1/admin/overall-dashboard", {headers: headers});
  }

}

export default MetricsService;