import React from "react";
import { FormattedMessage } from "react-intl";
import { Table } from "reactstrap";
import Secured from "./Secured";

const DataTable = ({columns, data, emptyList = "No data", selected, onSelect, securedActions = false, renderRowActions, actionsHeaderStyle, ...props}) => {

  return (
    <Table {...props}>
      <thead>
        <tr>
          {columns.map((col, index) => {
            return (
              <th className={col.headerClassName} style={col.style} key={index}>{col.headerKey ? <FormattedMessage id={col.headerKey} /> : col.header}</th>
            );
          })}
          {renderRowActions ? securedActions ? <Secured><th style={actionsHeaderStyle}></th></Secured> : <th style={actionsHeaderStyle}></th> : null }
        </tr>
      </thead>
      <tbody>
          {data.length === 0 && <tr><td colSpan={columns.length} className="text-center">{emptyList}</td></tr>}
          {data.map((row, rowIndex) => {

            const cols = columns.map((col, index) => {
              return (
                <td key={index} className={col.className} role={onSelect ? "button" : null} onClick={onSelect ? () => onSelect(row, rowIndex) : null}>{col.selector(row, rowIndex)}</td>
              );

            });

            return (
              <tr key={rowIndex} className={selected && selected(row)?'table-active':null}>
                {cols}
                {renderRowActions ? securedActions ? <Secured><td>{renderRowActions(row, rowIndex)}</td></Secured>: <td>{renderRowActions(row, rowIndex)}</td>: null}
              </tr>
            );
          })}
      </tbody>
    </Table>
  );
};

export default DataTable;