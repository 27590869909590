import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useRef, useState } from "react"
import { FormattedMessage, FormattedNumber, useIntl } from "react-intl";
import CustomSpinner from "../../commons/components/Spinner";
import PrintOrderService from "../services/PrintOrderService";
import ListPagination from "../../commons/components/ListPagination";
import DataTable from "../../commons/components/DataTable";
import Moment from "react-moment";
import { Button, Card, CardBody, CardHeader, Form, Input } from "reactstrap";
import CustomFade from "../../commons/components/CustomFade";
import { useForm } from "react-hook-form";
import { isNullOrUndef } from "chart.js/helpers";

const TYPE_CS = "COPY_SHOP";
const TYPE_DELIVERY = "DELIVERY";

const STATUS_WAIT_FOR_PRINT = "WAIT_FOR_PRINT";
const STATUS_PRINTED = "PRINTED";
const STATUS_PAID = "PAID";
const STATUS_NO_PAYMENT = "NO_PAYMENT";
const STATUSES_TO_FILTER = [
  STATUS_WAIT_FOR_PRINT,
  STATUS_PRINTED
];

const STATUSES_TO_PRINT = [
  STATUS_PAID,
  STATUS_NO_PAYMENT
]

const dataTableColumns = [
  {
    headerKey: "printing.printOrder.fields.userEmail",
    selector: row => row.email
  },
  {
    headerKey: "printing.printOrder.fields.type",
    selector: row => <FormattedMessage id={`printing.printOrder.fields.type.${row.type}`} />
  },
  {
    headerKey: "printing.printOrder.fields.status",
    selector: row => <FormattedMessage id={`printing.printOrder.fields.status.${row.status}`} />
  },
  {
    headerKey: "printing.printOrder.fields.price",
    headerClassName: "text-end",
    className: "text-end",
    style: {width: "85px"},
    selector: row => <span><FormattedNumber value={row.price / 100} maximumFractionDigits={2} />&nbsp;€</span>
  },
  {
    headerKey: "printing.printOrder.fields.pages",
    headerClassName: "text-end",
    className: "text-end",
    style: {width: "85px"},
    selector: row => row.pages
  },
  {
    headerKey: "printing.printOrder.fields.numDocs",
    headerClassName: "text-end",
    className: "text-end",
    style: {width: "115px"},
    selector: row => row.numDocs
  },
  {
    headerKey: "printing.printOrder.fields.created",
    style: {width: "135px"},
    selector: row => <Moment parse="DD/MM/YYYY" format="DD/MM/YYYY">{row.created}</Moment>
  }
];

const PrintOrderList = () => {

  const pageSize = 10;
  const sectionRef = useRef(null);

  const [currentPage, setCurrentPage] = useState(0);
  const [page, setPage] = useState({total: 0, list: []});
  const [showFilter, setShowFilter] = useState(false);
  const [filter, setFilter] = useState({});
  const [selectedRow, setSelectedRow] = useState();

  const intl = useIntl();

  const result = useQuery({
    queryKey: ["print-order-list", currentPage, pageSize, filter], 
    queryFn: () => PrintOrderService.list(currentPage, pageSize, filter),
    onSuccess: (response) => setPage({total: response.data.total, list: response.data.data })
  });

  const onPageChange = (e, pageTotLoad) => {
    e.preventDefault();

    if (result.isLoading) {
      return;
    }

    setCurrentPage(pageTotLoad);
  }

  const toggleFilter = () => {
    setShowFilter(oldValue =>!oldValue);
  }

  const handleOnSelectRow = (row) => {

    if (!isNullOrUndef(selectedRow) && selectedRow.externalId === row.externalId) {
      setSelectedRow(null);
    } else {
      setSelectedRow(row);
    }
  }

  let content = null;
  let detail = null;

  if (result.isLoading) {
    content = (
      <CustomSpinner messageKey="printing.printOrder.loading" />
    );
  } else if (result.isError) {
    content = (
      <FormattedMessage id="printing.printOrder.error" />
    );
  } else {
    const pagination = <ListPagination page={currentPage} totalItems={page.total} pageSize={pageSize} onPageChange={onPageChange} showSinglePage={false} />;

    content = (
      <>
        <DataTable responsive bordered hover className='print-order-table' data={page.list} columns={dataTableColumns}
          onSelect={handleOnSelectRow} selected={row => selectedRow?.externalId === row.externalId}
          emptyList={intl.formatMessage({id:"printing.printOrder.empty"})} />
        {pagination}
      </>
    );

    detail = (
      <Card>
        <CardBody className="text-center">
          <FormattedMessage id="printing.printOrder.detail.selectOneRow" />
        </CardBody>
      </Card>
    );

    if (selectedRow) {
      detail = (
        <Card>
          <CardHeader>
            <FormattedMessage id="printing.printOrder.detail" />
          </CardHeader>
          <CardBody>
          <div className="d-flex flex-column gap-2">
          { selectedRow.type === TYPE_CS && <>
            <div className="fw-bold">
              <FormattedMessage id="printing.printOrder.detail.copyShop" />
            </div>
            <div>{selectedRow.name}</div>
            <div>{selectedRow.address}</div>
            <div>{selectedRow.city}</div>
          </> }
          { selectedRow.type === TYPE_DELIVERY && <>
            <div className="fw-bold">
              <FormattedMessage id="printing.printOrder.detail.delivery.address" />
            </div>
            <div>{selectedRow.address}</div>
            <div>{selectedRow.postalCode} - {selectedRow.city}</div>
            <div>
              <span className="fw-bold">
                <FormattedMessage id="printing.printOrder.detail.delivery.estimatedDate" />:&nbsp;
              </span>
              {selectedRow.estimatedDate}
            </div>
          </> }
          {selectedRow.documents?.length > 0 && <>
            <div className="fw-bold">
              <FormattedMessage id="printing.printOrder.detail.documents" />
            </div>
            <ul>
              {selectedRow.documents.map((doc, index) => <li key={index}>{doc.name}<span className="ms-2 small"><i className="fas fa-file"></i>&nbsp;{doc.pages}</span></li>)}
            </ul>
          </>}
        </div>
          </CardBody>
        </Card>
        
      );
    }
  }

  

  return (
    <div>
      <div className='d-flex justify-content-between'>
        <div className="flex-grow-1">
          <h2 className={`section-title ${showFilter ? "with-filter":""} fs-5`} ref={sectionRef}>
            <FormattedMessage id="printing.printOrder" />
          </h2>
          <CustomFade open={showFilter} style={{width: sectionRef?.current?.offsetWidth}}>
            <div className="border rounded-bottom p-2">
              <PrintOrderListFilter onFilter={setFilter} />
            </div>
          </CustomFade>
        </div>
        <div className="d-flex">
          <Button className='ms-2' size='sm' style={{height: sectionRef?.current?.offsetHeight}} disabled={result.isLoading} onClick={toggleFilter}>
            <span className="fa-stack">
              <i className='fas fa-filter fa-stack-1x' />
              {showFilter && <i className="fa fa-slash fa-inverse fa-stack-1x" style={{marginTop: "-5px"}}></i> }
            </span>
          </Button>
        </div>
      </div>
     
      <div className='section-content'>
        {content}
        {detail}
      </div>
    </div>
  );
}

export default PrintOrderList;


const PrintOrderListFilter = ({onFilter}) => {
  const intl = useIntl();
  const { register, reset, handleSubmit, watch, setError, setFocus, formState: { errors } } = useForm({
    mode: "onChange"
  });

  const { ref: refOrderId, ...registerOrderId } = register('orderId');
  const { ref: refUserEmail, ...registerUserEmail } = register('userEmail');
  const { ref: refStatus, ...registerStatus } = register('status');

  const status = watch("status");

  useEffect(() => {
    //setFocus("name");
  }, [setFocus]);

  useEffect(() => {

      let statusFilter = [];
      if (STATUS_WAIT_FOR_PRINT === status) {
        statusFilter.push(...STATUSES_TO_PRINT);
      } else if (STATUS_PRINTED === status) {
        statusFilter.push(STATUS_PRINTED);
      }

      handleOnFilter({
        "orderId": watch("orderId"),
        "userEmail": watch("userEmail"),
        "statuses": statusFilter
      });

  }, [status]);
  

  const handleOnFilter = (data) => {
    if (typeof onFilter === "function") {
      onFilter(data);
    }
  }

  const handleReset = () => {
    // Reset form fields to default values
    reset({
      orderId: "",
      userEmail: "",
      status: ""
    });
    
    // Also trigger the filter with empty values
    handleOnFilter({
      orderId: "",
      userEmail: "",
      statuses: []
    });
  }

  return (
    <Form onSubmit={handleSubmit(handleOnFilter)}>
      <div className="d-block d-lg-flex align-items-center gap-4">
        
        <div className="mb-0">
          <Input id="status" name="status" innerRef={refStatus} {...registerStatus} type="select">
            <option value="">{intl.formatMessage({id: "printing.printOrder.filter.status"})}</option>
            {STATUSES_TO_FILTER.map(i => <option key={i} value={i}>{intl.formatMessage({id: `printing.printOrder.filter.status.${i}`})}</option>)}
          </Input>
        </div>

        <div className="mb-0">
          <Input id="userEmail" name="userEmail" placeholder={intl.formatMessage({id: "printing.printOrder.filter.userEmail"})} innerRef={refUserEmail} {...registerUserEmail} />
        </div>

        <div className="mb-0">
          <Input id="orderId" name="orderId" placeholder={intl.formatMessage({id: "printing.printOrder.filter.orderId"})} innerRef={refOrderId} {...registerOrderId} />
        </div>

        <div className="d-flex gap-2">
          <Button type="submit">
            <i className="fas fa-search"></i>
          </Button>

          <Button type="button" onClick={handleReset}>
            <i className="fas fa-times"></i>
          </Button>
        </div>

      </div>
    </Form>
  );
}