import api from "../../Api";
import { buildApiHeaders } from "../../commons/functions";

class PromoCodeService {

  static async values() {
    const headers = buildApiHeaders();
    return api.get("/v1/admin/printing/promo-codes/values", {headers: headers});
  }

  static async categories() {
    const headers = buildApiHeaders();
    return api.get("/v1/admin/printing/promo-codes/categories", {headers: headers});
  }

  static async list(page, pageSize, filter) {
    const headers = buildApiHeaders();
    return api.get("/v1/admin/printing/promo-codes", {params: {page: page, pageSize: pageSize, ...filter}, headers: headers});
  }
  
  static async create(data) {
    const headers = buildApiHeaders();
    return api.put("/v1/admin/printing/promo-codes", data, {headers: headers});
  }

  static async update(identifier, data) {
    const headers = buildApiHeaders();
    return api.post(`/v1/admin/printing/promo-codes/${identifier}`, data, {headers: headers});
  }

  static async delete(identifier) {
    const headers = buildApiHeaders();
    return api.delete(`/v1/admin/printing/promo-codes/${identifier}`, {headers: headers});
  }

}

export default PromoCodeService;