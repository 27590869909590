import React from "react";
import { Col, Container, Row } from "reactstrap";
import { Page } from "../../commons/components/Page";
import PromoCodeList from "../components/PromoCodeList";

const PromoCodePage = () => {

  return (
    <Page>
      <Container fluid>
        <Row>
          <Col>
            <PromoCodeList />
          </Col>
        </Row>
      </Container>
    </Page>
  );
}

export default PromoCodePage;