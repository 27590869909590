import React, { useState, Fragment, useEffect, useRef } from "react";

import { FormattedMessage, FormattedNumber, useIntl } from "react-intl";
import { useMutation, useQuery } from "@tanstack/react-query";
import PromoCodeService from "../services/PromoCodeService";
import ListPagination from "../../commons/components/ListPagination";
import DataTable from "../../commons/components/DataTable";
import { Button, Fade, Form, Input, InputGroup, InputGroupText, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import CustomSpinner from "../../commons/components/Spinner";
import { handleApiErrors, handleErrors } from "../../commons/functions";
import { toast } from 'react-toastify';
import PromoCodeForm from "./PromoCodeForm";
import { useForm } from "react-hook-form";
import Moment from "react-moment";
import CustomFade from "../../commons/components/CustomFade";

const dataTableColumns = [
  {
    headerKey: "printing.promoCodes.fields.category",
    selector: row => row.category
  },
  {
    headerKey: "printing.promoCodes.fields.name",
    selector: row => row.name
  },
  {
    headerKey: "printing.promoCodes.fields.code",
    selector: row => row.code
  },
  {
    headerKey: "printing.promoCodes.fields.startDate",
    style: {width: "135px"},
    selector: row => <Moment parse="YYYY-MM-DD" format="DD/MM/YYYY">{row.startDate}</Moment>
  },
  {
    headerKey: "printing.promoCodes.fields.endDate",
    style: {width: "135px"},
    selector: row => <Moment parse="YYYY-MM-DD" format="DD/MM/YYYY">{row.endDate}</Moment>
  },
  {
    headerKey: "printing.promoCodes.fields.value",
    headerClassName: "text-end",
    className: "text-end",
    style: {width: "85px"},
    selector: row => <span><FormattedNumber value={row.value / 100} maximumFractionDigits={2} />&nbsp;€</span>
  },
  {
    headerKey: "printing.promoCodes.fields.timesUsed",
    headerClassName: "text-end",
    className: "text-end",
    style: {width: "145px"},
    selector: row => <span>{row.timesUsed}</span>
  }
];

const PromoCodeList = () => {

  const pageSize = 10;

  const sectionRef = useRef(null);

  const [values, setValues] = useState([]);
  const [categories, setCategories] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [page, setPage] = useState({total: 0, list: []});
  const [selectedRow, setSelectedRow] = useState({valueName: "1_00"});
  const [openForm, setOpenForm] = useState(false);
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [filter, setFilter] = useState({});
  
  const intl = useIntl();

  const result = useQuery({
    queryKey: ["printing-promo-code-list", currentPage, pageSize, filter], 
    queryFn: () => PromoCodeService.list(currentPage, pageSize, filter),
    onSuccess: (response) => setPage({total: response.data.total, list: response.data.data })
  });

  useQuery({
    queryKey: ["printing-promo-code-values"], 
    queryFn: () => PromoCodeService.values(),
    onSuccess: (response) => setValues(response.data)
  });

  useQuery({
    queryKey: ["printing-promo-code-categories"], 
    queryFn: () => PromoCodeService.categories(),
    onSuccess: (response) => setCategories(response.data)
  });

  const addMutation = useMutation(([data, setError]) => PromoCodeService.create(data), {
    onSuccess: (response, vars, context) => {
      toast.success(intl.formatMessage({id: "printing.promoCodes.msg.create"}));
      result.refetch();
      toggleForm();
    },
    onError: (error, [data, setError], context) => {
      handleErrors(error, "printing.promoCodes.msg.error.create", setError, toast, intl);
    }
  });

  const deleteMutation = useMutation((identifier) => PromoCodeService.delete(identifier), {
    onSuccess: (response, [identifier], context) => {
      toast.success(intl.formatMessage({id: "printing.promoCodes.msg.delete"}));
      result.refetch();
    },
    onError: (error, vars, context) => toast.error(handleApiErrors(intl, error, "printing.promoCodes.msg.error.delete")),
    onSettled: () => {
      toggleDelete();
    }
  });

  const updateMutation = useMutation(([identifier, data, setError]) => PromoCodeService.update(identifier, data), {
    onSuccess: (response, vars, context) => {
      toast.success(intl.formatMessage({id: "printing.promoCodes.msg.update"}));
      result.refetch();
      toggleForm();
    },
    onError: (error, [identifier, data, setError], context) => {
      handleErrors(error, "printing.promoCodes.msg.error.update", setError, toast, intl);
    }
  });

  useEffect(() => {
    if (!openForm) {
      setSelectedRow(null);
    }
  }, [openForm]);

  useEffect(() => {
    if (!openDeleteConfirm) {
      setSelectedRow(null);
    }
  }, [openDeleteConfirm]);

  useEffect(() => {

    if (!showFilter) {
      setFilter({});
      setCurrentPage(0);
    }
    
  }, [showFilter]);
  

  const onPageChange = (e, pageTotLoad) => {
    e.preventDefault();

    if (result.isLoading) {
      return;
    }

    setCurrentPage(pageTotLoad);
  }

  const toggleForm = () => {
    setOpenForm(oldValue =>!oldValue);
  };

  const toggleDelete = () => {
    setOpenDeleteConfirm(oldValue =>!oldValue);
  };

  const toggleFilter = () => {
    setShowFilter(oldValue =>!oldValue);
  }
  
  const handleSave = (data, setError) => {
    if (selectedRow) {
      updateMutation.mutate([selectedRow.id, data, setError]);
    } else {
      addMutation.mutate([data, setError]);
    }
  };

  const handleDelete = () => {
    deleteMutation.mutate([selectedRow.id]);
  };

  const handleConfirmDelete = (row) => {
    setSelectedRow(row);
    toggleDelete();
  };

  const handleUpdate = (row) => {
    setSelectedRow(row);
    toggleForm();
  }


  let content = null;

  if (result.isLoading) {
    content = (
      <CustomSpinner messageKey="printing.promoCodes.loading" />
    );
  } else if (result.isError) {
    content = (
      <FormattedMessage id="printing.promoCodes.loading.error" />
    );
  } else {

    const pagination = <ListPagination page={currentPage} totalItems={page.total} pageSize={pageSize} onPageChange={onPageChange} showSinglePage={false} />;

    content = (
      <Fragment>
        <DataTable responsive bordered hover className='printing-promo-codes-table' data={page.list} columns={dataTableColumns}
          actionsHeaderStyle={{width: "60px"}} renderRowActions={(row, rowIndex) => {
                      return (
                        <Fragment>
                          { !filter.userEmail && row.timesUsed === 0 && <i className="fas fa-edit me-2" role="button" onClick={() => handleUpdate(row)}></i> }
                          { row.timesUsed === 0 && <i className="fas fa-trash-alt" role="button" onClick={() => handleConfirmDelete(row)}></i> }
                        </Fragment>
                      );
          
                    }}/>
        {pagination}
      </Fragment>
    );

  }

  return (
    <div>
      <div className='d-flex justify-content-between'>
        <div className="flex-grow-1">
          <h2 className={`section-title ${showFilter ? "with-filter":""} fs-5`} ref={sectionRef}>
            <FormattedMessage id="printing.promoCodes" />
          </h2>
          <CustomFade open={showFilter} style={{width: sectionRef?.current?.offsetWidth}}>
            <div className="border rounded-bottom p-2">
              <PromoCodeListFilter onFilter={setFilter} categories={categories} />
            </div>
          </CustomFade>
        </div>
        <div className="d-flex">
          <Button className='ms-2' size='sm' style={{height: sectionRef?.current?.offsetHeight}} disabled={result.isLoading || addMutation.isLoading || updateMutation.isLoading} onClick={toggleFilter}>
            <span className="fa-stack">
              <i className='fas fa-filter fa-stack-1x' />
              {showFilter && <i className="fa fa-slash fa-inverse fa-stack-1x" style={{marginTop: "-5px"}}></i> }
            </span>
          </Button>
          <Button className='ms-2' size='sm' style={{height: sectionRef?.current?.offsetHeight}} disabled={result.isLoading || addMutation.isLoading || updateMutation.isLoading} onClick={toggleForm}>
            <i className='fas fa-plus me-0 me-md-2' />
            <span className='d-none d-md-inline'>
              <FormattedMessage id="printing.promoCodes.buttons.add" />
            </span>
          </Button>
        </div>
      </div>
     
      <div className='section-content'>
        {content}
      </div>
      {openDeleteConfirm && <DeleteModal open={openDeleteConfirm} toggle={toggleDelete} onConfirm={handleDelete} disabled={deleteMutation.isLoading} /> }
      {openForm && <ModalForm open={openForm} toggle={toggleForm} onSave={handleSave} data={selectedRow} disabled={addMutation.isLoading || updateMutation.isLoading}
        headerKey={selectedRow ? "printing.promoCodes.edit": "printing.promoCodes.add"} values={values} categories={categories} /> }
    </div>
  );
}

export default PromoCodeList;

const DeleteModal = ({open, toggle, onConfirm, disabled = false}) => {

  return (
    <Modal isOpen={open} toggle={toggle} centered>
      <ModalHeader toggle={toggle}>
        <FormattedMessage id="confirm" />
      </ModalHeader>
      <ModalBody>
        <FormattedMessage id="printing.promoCodes.delete" />
      </ModalBody>
      <ModalFooter>
        <div className='d-flex justify-content-end'>
          <Button color='danger' onClick={onConfirm} disabled={disabled}>
            <FormattedMessage id="printing.promoCodes.buttons.delete" />
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

const ModalForm = ({open, toggle, headerKey, data, disabled = false, onSave, values, categories}) => {
  return (
    <Modal isOpen={open} toggle={toggle} centered>
      <ModalHeader toggle={toggle}>
        <FormattedMessage id={headerKey} />
      </ModalHeader>
      <ModalBody>
        <PromoCodeForm id="printing-promoCodes-form" onSave={onSave} defaultValues={data} disabled={disabled} values={values} categories={categories} />
      </ModalBody>
      <ModalFooter>
      <div className='d-flex justify-content-end'>
        <Button color='primary' disabled={disabled} type="submit" form="printing-promoCodes-form">
          <FormattedMessage id="printing.promoCodes.buttons.save" />
        </Button>
      </div>
      </ModalFooter>
    </Modal>
  );
};

const PromoCodeListFilter = ({onFilter, categories}) => {

  const intl = useIntl();
  const { register, handleSubmit, watch, setError, setFocus, formState: { errors } } = useForm({
    mode: "onChange"
  });

  const { ref: refActive, ...registerActive } = register('active');
  const { ref: refQuery, ...registerQuery } = register('query');
  const { ref: refUserEmail, ...registerUserEmail } = register('userEmail');
  const { ref: refCategory, ...registerCategory } = register('category');

  const active = watch("active");
  const category = watch("category");

  useEffect(() => {
    //setFocus("name");
  }, [setFocus]);

  useEffect(() => {

    if (active !== undefined && active !== null) {
      handleOnFilter({
        "active": active,
        "query": watch("query"),
        "userEmail": watch("userEmail"),
        "category": category
      });
    }

  }, [active, category]);
  

  const handleOnFilter = (data) => {
    if (typeof onFilter === "function") {
      onFilter(data);
    }
  }

  return (
    <Form onSubmit={handleSubmit(handleOnFilter)}>
      <div className="d-block d-lg-flex align-items-center gap-4">
        <div className="d-flex align-items-center gap-2 mb-2 mb-lg-0">
          <div>
            <Input id="activeFilter" name="active" type="checkbox" innerRef={refActive} {...registerActive} />
          </div>
          <Label for="activeFilter" className="mb-0">
            <FormattedMessage id="printing.promoCodes.filter.fields.active" />
          </Label>
        </div>
        
        <div className="mb-0">
          <Input id="category" name="category" innerRef={refCategory} {...registerCategory} type="select">
            <option value="">{intl.formatMessage({id: "printing.promoCodes.filter.fields.category"})}</option>
            {categories.map(i => <option key={i.key} value={i.key}>{i.value}</option>)}
          </Input>
        </div>

        <div className="mb-0">
          <Input id="userEmail" name="userEmail" placeholder={intl.formatMessage({id: "printing.promoCodes.filter.fields.userEmail"})} innerRef={refUserEmail} {...registerUserEmail} />
        </div>

        <div className="mb-0">
          <InputGroup>
            <Input id="queryFilter" name="query" placeholder={intl.formatMessage({id: "printing.promoCodes.filter.fields.query"})} innerRef={refQuery} {...registerQuery} />
            <Button type="submit">
              <i className="fas fa-search"></i>
            </Button>
          </InputGroup>
        </div>
      </div>
    </Form>
  );

}